import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Projects from "../components/projects"
import Footer from "../components/footer"

export default () => {
  return (
    <Layout>
      <Header />
      <Projects />
      <Footer />
    </Layout>
  )
}
